export default {
  dateRecord: {
    lastDay: 1,
  },
  empGrpList: [
    {
      id: 1,
      value: "Billable",
    },
    {
      id: 2,
      value: "Interns",
    },
    {
      id: 3,
      value: "Non-Billable",
    },
  ],
};

// Defines the time sheet entry limit in seconds
export const TIMESHEET_ENTRY_LIMIT: number = 4 * 60 * 60;

/**
 * Defines the headers of the excel file used for bulk upload of
 * missing timesheet entries
 */
export enum TIMESHEET_BULK_UPLOAD_HEADERS {
  EMPLOYEE_ID = "Employee ID",
  PROJECT_NAME = "Project Name",
  DATE = "Date",
  START_TIME = "Start Time",
  END_TIME = "End Time",
  PROJECT_WORK_DESCRIPTION = "Project Work Description",
}
