// Angular
import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";

// Angular Material
import { MatSnackBar } from "@angular/material";

// Services
import { LoginService } from "src/app/services/login.service";

@Component({
  selector: "app-forgotpassword",
  templateUrl: "./forgotpassword.component.html",
  styleUrls: ["./forgotpassword.component.scss"],
})
export class ForgotpasswordComponent implements OnInit {
  task: string = "resetPassword";
  date: string;
  message: string;
  resetPasswordForm: FormGroup;
  submitted: boolean = false;
  showMessage: boolean = false;

  user: any = {
    emailId: "",
    task: this.task,
  };

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private loginService: LoginService
  ) {}

  ngOnInit() {
    // Initializing the reset password form
    this.resetPasswordForm = this.formBuilder.group({
      emailId: new FormControl("", {
        validators: [Validators.required, Validators.pattern('^[a-zA-Z][a-zA-Z0-9._-]*')],
      }),
    });
  }

  openSnackBar(msg, dur) {
    this.snackBar.open(msg, "Close", {
      duration: dur,
    });
  }

  forgotPwd() {
    this.submitted = true;
    this.showMessage = false;
    this.message = "";

    if (this.resetPasswordForm.invalid) {
      return;
    }

    this.loginService.forgotPassword(this.user).subscribe(
      (res) => {
        if (res["statusCode"] == undefined || res["statusCode"] == "501") {
          this.showMessage = true;
          this.message = res["message"];
          this.openSnackBar(this.message, 2000);
        }
        if (res["statusCode"] == undefined || res["statusCode"] == "200") {
          this.openSnackBar("Mail sent Successfully", 500);
          this.router.navigate(["/login"]);
        }
      },
      (err) => console.error(err)
    );
  }

  get f() {
    return this.resetPasswordForm.controls;
  }
}
