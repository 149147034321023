// Angular
import { Router } from "@angular/router";
import { Component, Inject } from "@angular/core";

// Angular Material
import { MatSnackBar } from "@angular/material";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

// 3rd party packages
import * as XLSX from "xlsx";

// Services
import { AdminService } from "src/app/services/admin.service";
import { AuthenticationService } from "src/app/services/authentication.service";

// Helpers
import { getDateStrFromDateObj } from "../../helper/clientCommonFunction";

// Configurations
import { TIMESHEET_BULK_UPLOAD_HEADERS } from "../../../assets/clientConfig";

// TODO: Move interface and type declarations to their separate files
type AOA = any[][];
export interface pro {
  value: any;
  proid: any;
}
export interface Data {
  id: number;
  name: string;
}

@Component({
  selector: "app-ts-form",
  templateUrl: "./ts-form.component.html",
  styleUrls: ["./ts-form.component.scss"],
})
export class TsFormComponent {
  // TODO: Move the session storage variable name to configuration
  data1: AOA = [];
  willDownload = false;
  pros: pro[] = [];
  userid = window.sessionStorage.getItem("userid");

  constructor(
    private adminService: AdminService,
    private authService: AuthenticationService,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<TsFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Data,
    private router: Router
  ) {}

  onFileChange(ev) {
    let workBook = null;
    let jsonData = null;
    const reader = new FileReader();
    const file = ev.target.files[0];
    reader.onload = (event) => {
      const data = reader.result;
      workBook = XLSX.read(data, { type: "binary" });
      jsonData = workBook.SheetNames.reduce((initial, name) => {
        const sheet = workBook.Sheets[name];
        initial[name] = XLSX.utils.sheet_to_json(sheet);
        return initial;
      }, {});
      this.getProjects(jsonData);
    };
    reader.readAsBinaryString(file);
  }

  createJson(req_data) {
    var dataArr = [];
    for (var mainKey in req_data) {
      for (var key in req_data[mainKey]) {
        var reqData: any = [];
        for (var i = 0; i < this.pros.length; i++) {
          if (
            req_data[mainKey][key][
              TIMESHEET_BULK_UPLOAD_HEADERS.PROJECT_NAME
            ].replace(/\s/g, "") == this.pros[i]["value"].replace(/\s/g, "")
          ) {
            // Adding the project ID and project name from the server response to request data
            req_data[mainKey][key]["proid"] = this.pros[i]["proid"];
            req_data[mainKey][key][TIMESHEET_BULK_UPLOAD_HEADERS.PROJECT_NAME] =
              this.pros[i]["value"];

            // Formatting and adding date to the request data
            var dt = req_data[mainKey][key][TIMESHEET_BULK_UPLOAD_HEADERS.DATE];
            var y = dt.split("/")[2];
            var m = dt.split("/")[1];
            var d = dt.split("/")[0];
            var req_date = "20" + y + "-" + m + "-" + d;

            reqData.push(
              req_data[mainKey][key][TIMESHEET_BULK_UPLOAD_HEADERS.EMPLOYEE_ID]
            );
            reqData.push(req_date);

            var st = new Date(
              req_date +
                "T" +
                req_data[mainKey][key][TIMESHEET_BULK_UPLOAD_HEADERS.START_TIME]
            );

            reqData.push(st);

            var end = new Date(
              req_date +
                "T" +
                req_data[mainKey][key][TIMESHEET_BULK_UPLOAD_HEADERS.END_TIME]
            );

            reqData.push(end);
            reqData.push(this.pros[i]["proid"]);
            if (
              req_data[mainKey][key][
                TIMESHEET_BULK_UPLOAD_HEADERS.PROJECT_WORK_DESCRIPTION
              ] == undefined
            )
              reqData.push("");
            else
              reqData.push(
                req_data[mainKey][key][
                  TIMESHEET_BULK_UPLOAD_HEADERS.PROJECT_WORK_DESCRIPTION
                ]
              );
            reqData.push(getDateStrFromDateObj(new Date()));
            reqData.push(getDateStrFromDateObj(new Date()));
          }
        }
        if (
          req_data[mainKey][key][TIMESHEET_BULK_UPLOAD_HEADERS.START_TIME] !=
            undefined &&
          req_data[mainKey][key][TIMESHEET_BULK_UPLOAD_HEADERS.END_TIME] !=
            undefined
        ) {
          var dur = this.getDurationForJson(
            req_data[mainKey][key][TIMESHEET_BULK_UPLOAD_HEADERS.START_TIME],
            req_data[mainKey][key][TIMESHEET_BULK_UPLOAD_HEADERS.END_TIME]
          );
          req_data[mainKey][key]["duration"] = dur;
          reqData.push(dur);
        }
        // console.log(reqData)
        dataArr.push(reqData);
      }
      // console.log("last", req_data)
    }

    console.log("createJson() : data array : ", dataArr);
    // console.log("final", dataArr)
    var req_Json = {};
    req_Json["data"] = dataArr;
    req_Json["keys"] = [
      "user_id",
      "entry_date",
      "start_time",
      "end_time",
      "project_id",
      "description",
      "created_date",
      "updated_date",
      "duration",
    ];
    console.log(req_Json);
    // this.onNoClick();
    // return
    this.adminService.saveMissingTimesheet(req_Json).subscribe(
      (res) => {
        if (res["statusCode"] == 501) {
          this.openSnackBar(res["message"], 2000);
          this.router.navigate(["/timesheet"]);
        } else {
          console.log("res", res);
          if (res["statusCode"] == undefined || res["statusCode"] == 200) {
            this.openSnackBar("Excel Uploaded Successfully!", 3000);
            this.closeExcelUploadDialog();
          }
          if (res["statusCode"] == 500) {
            this.openSnackBar("Error occured while Uploadeding Excel!", 5000);
            this.closeExcelUploadDialog();
          }
          if (res["statusCode"] == "401") {
            localStorage.clear();
            this.router.navigate(["/login"]);
          }
        }
      },
      (err) => console.error(err)
    );
  }

  getProjects(jsonData) {
    var jsonObj = {};
    jsonObj["user_id"] = this.userid;
    jsonObj["action"] = "timesheet";
    this.authService.getAllProjForReportOrPlanning(jsonObj).subscribe(
      (res) => {
        if (res["statusCode"] == "200") {
          res = res["data"];
          var len = res.length;
          this.pros = [];
          if (len > 0) {
            for (var i = 0; i < len; i++) {
              if (res[i]["project_code"].split("-")[0] == "p") {
                this.pros.push({
                  value:
                    res[i]["project_name"] + "_" + res[i]["project_code_old"],
                  proid: res[i]["id"],
                });
              } else {
                this.pros.push({
                  value: res[i]["project_name"] + "_" + res[i]["project_code"],
                  proid: res[i]["id"],
                });
              }
            }

            this.createJson(jsonData);
          } else if (len == 0) {
            setTimeout(() => {
              this.router.navigate(["/timesheet"]);
            }, 2010);
          }
        }
        if (res["statusCode"] == "500") {
          // this.openSnackBar(res["message"], 2000)
          setTimeout(() => {
            this.router.navigate(["/timesheet"]);
          }, 2010);
        }
        if (res["statusCode"] == "401") {
          localStorage.clear();
          this.router.navigate(["/login"]);
        }
      },
      (err) => console.error(err)
    );
    return;
  }

  getDurationForJson(start, end) {
    // console.log(start, end)
    var hms = start;
    var a = hms.split(":");
    var seconds = +a[0] * 60 * 60 + +a[1] * 60;
    var hm = end;
    var b = hm.split(":");
    var second = +b[0] * 60 * 60 + +b[1] * 60;
    var result = second - seconds;
    var durationString = this.displayDuration(result);
    return result;
  }

  displayDuration(req_val) {
    var d = req_val;
    const HOUR = 60 * 60;
    const MINUTE = 60;
    var minutesInSeconds = d % HOUR;
    var hours = Math.floor(d / HOUR);
    var minutes = Math.floor(minutesInSeconds / MINUTE);
    var hr, min;
    if (hours < 10) {
      hr = "" + "0" + hours;
    } else {
      hr = "" + hours;
    }
    if (minutes < 10) {
      min = "" + "0" + minutes;
    } else {
      min = "" + minutes;
    }
    var dur = hr + ":" + min;
    return dur;
  }

  openSnackBar(msg, dur) {
    this.snackBar.open(msg, "Close", {
      duration: dur,
    });
  }

  // Helper functions

  /**
   * Closes the Angular material dialog for excel file upload
   */
  private closeExcelUploadDialog(): void {
    this.dialogRef.close();
  }
}
